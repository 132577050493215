<template>
  <div class="home-section">
    <div class="tab-page cant-tab-page">
      <!-- <Header>
        <div>先学后付</div>
      </Header> -->
        <router-view style="background-color: #f5f5f5;flex:1;overflow:hidden"></router-view>
    </div>
    <van-tabbar v-if="showTabs"
      class="tab-bar"
      v-model="$store.state.home.active"
      active-color="#E79C4C"
      @change="tabChange"
    >
      <van-tabbar-item name="/" style="background-color: #000">
        <span>首页</span>
        <template #icon="props">
          <img v-if="props.active" src="@/assets/img/shouye1.png" />
          <img v-else src="@/assets/img/shouye2.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="/payment" style="background-color: #000">
        <span>付款</span>
        <template #icon="props">
          <img v-if="props.active" src="@/assets/img/fukuan1.png" />
          <img v-else src="@/assets/img/fukuan2.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="/my" style="background-color: #000">
        <span>我的</span>
        <template #icon="props">
          <img v-if="props.active" src="@/assets/img/wode1.png" />
          <img v-else src="@/assets/img/wode2.png" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { checkUserToken } from '@/api/login'
// import Header from "../../components/header.vue";
export default {
  name: "",
  // components: {
  //   Header,
  // },
  data() {
    return {
      showTabs: false,
    };
  },

  created(){
    this.showTabs = localStorage.getItem("isOldUser") == 1
    console.log(this.$route.query.type)
    if (this.$route.query.type) {
      localStorage.setItem("manage_temp_no", this.$route.query.type)
    }
    this.checkLogin()
  },
  methods: {
    tabChange(active) {
      const router = this.$router;
      if (active == '/') {
        const queryId = localStorage.getItem("queryId")
        if (queryId) {
          active += '?business_id=' + queryId 
        }
      }
      router.push({
        path: active,
      });
    },

    async checkLogin(){
      if(sessionStorage.getItem("insession") == 1){
        return
      }
      sessionStorage.setItem("insession", 1);

      const { data, code } = await checkUserToken()
      if (code == 200) {
        localStorage.setItem("token", data.token);
        localStorage.setItem('realName',data.realName)
        localStorage.setItem("nickname", data.nickname);
        localStorage.setItem("isOldUser", data.isWithBill ? 1 : '');

        this.showTabs = data.isWithBill ? 1 : ''
      }
    },
  },
};
</script>
<style scoped lang="scss">
.home-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  //justify-content: flex-start;
  .cant-tab-page {
    // height: calc(100% - 50px);
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .tab-bar {
    background-color: #000000;
    position: relative;
  }
}
</style>