import ajax from '@/utils/ajax.js'

/**
 * 手机号登录
 */
export function login(params) {
  return ajax.post('/api/login',params)
}

/**
 * token检测
 */
export function checkUserToken(params) {
  return ajax.post('/api/checkUserToken',params)
}

/**
 * 发送验证码
 */
 export function verifyLoginSmsCode(params) {
  return ajax.post('/api/verifyLoginSmsCode',params)
}

/**
 * 退出登录
 */
 export function logout(params) {
  return ajax.post('/api/logout',params)
}


